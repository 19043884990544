import { FC } from 'react';
import { AnimatePresence, motion, VariantLabels } from 'framer-motion';
import useBem from '@indicia/use-bem';

import { FormStepType } from '../../types';

import './form-step.scss';

const FormStep: FC<FormStepType> = ({ children = undefined, title, description, stepNumber = 1, currentStep = 1 }) => {
  const { bemClassName } = useBem('form-step');

  const variants = {
    hidden: { position: 'absolute', opacity: 0 },
    show: { x: 0, position: 'relative', opacity: 1 },
    exitToPrevious: { x: '-100vw', position: 'absolute', opacity: 0 },
    exitToNext: { x: '100vw', position: 'absolute', opacity: 0 },
  };

  const handleStepAnimation = (step: number): VariantLabels => {
    switch (true) {
      case step < currentStep:
        return 'exitToPrevious';
      case step > currentStep:
        return 'exitToNext';
      case step === currentStep:
        return 'show';
      default:
        return 'hidden';
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={stepNumber + 1 === currentStep ? 'show' : 'hidden'}
        // @ts-ignore
        variants={variants}
        transition={{ ease: 'easeInOut', duration: 0.5 }}
        animate={handleStepAnimation(stepNumber + 1)}
        className={bemClassName()}
      >
        <h2 className={bemClassName('title')}>{title}</h2>
        <p className={bemClassName('description')}>{description}</p>
        <div className={bemClassName('step-content')}>{children}</div>
      </motion.div>
    </AnimatePresence>
  );
};

export default FormStep;
