enum BookingProcessSteps {
  ContactInformation = 1,
  GuestInformation = 2,
  ConferenceRoomSelection = 3,
  AccommodationsSelection = 4,
  AdditionalRoomSelection = 5,
  ReviewAndConfirm = 6,
  Payment = 7,
}

enum BookingStatus {
  Initialized = 'Initialized',
  ConfirmedCancelled = 'ConfirmedCancelled',
  Rejected = 'Rejected',
  PaymentPending = 'PaymentPending',
  PaymentSucceeded = 'PaymentSucceeded',
  PaymentFailed = 'PaymentFailed',
}

export { BookingProcessSteps, BookingStatus };
