import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormStep from '../../../../shared/components/form/react-hook-form-components/form-step/form-step';
import FormInputField from '../../../../shared/components/form/react-hook-form-components/form-fields/form-input-field';
import { FormInputEnum, FormStepType } from '../../../../shared/components/form/types';
import { emailRegex, phoneRegex } from '../../data/utils/regexes';
import useBookingFormContext from '../../data/context/use-booking-form-context';
import ContactInformationAction from '../../data/reducers/contact-information-reducer/contact-information-actions';

const ContactInformationStep: FC<FormStepType> = ({ stepNumber = 1, currentStep = 1 }) => {
  const { t } = useTranslation('common', { keyPrefix: 'booking-form.contact-information' });
  const { t: tGeneric } = useTranslation('common', { keyPrefix: 'generic' });
  const {
    contactInformation: { emailAddress, emailAddressError, phoneNumber, phoneNumberError, name, nameError },
    contactInformationDispatcher,
  } = useBookingFormContext();

  const validateEmail = () => {
    let message = '';
    const field = t('email');

    if (emailAddress === undefined || emailAddress === '') {
      message = tGeneric('required', { field });
    } else if (!emailRegex.test(emailAddress)) {
      message = tGeneric('invalid', { field });
    } else if (emailAddress.length > 254) {
      message = tGeneric('too-long', { field, maxLength: 254 });
    }

    contactInformationDispatcher({ type: ContactInformationAction.SetEmailError, payload: message });
  };

  const validateName = () => {
    let message = '';
    const field = t('name');

    if (name === undefined || name === '') {
      message = tGeneric('required', { field });
    }

    contactInformationDispatcher({ type: ContactInformationAction.SetNameError, payload: message });
  };

  const validatePhoneNumber = () => {
    let message = '';
    const field = t('phone-number');

    if (phoneNumber === undefined || phoneNumber === '') {
      message = tGeneric('required', { field });
    } else if (!phoneRegex.test(phoneNumber)) {
      message = tGeneric('invalid', { field });
    }

    contactInformationDispatcher({ type: ContactInformationAction.SetPhoneError, payload: message });
  };

  return (
    <FormStep
      title={t(`title`)}
      description={t(`description`)}
      stepNumber={stepNumber}
      currentStep={currentStep}
    >
      <FormInputField
        name="contactEmail"
        formInputType={FormInputEnum.Text}
        label={t('name')}
        isFilled={!!name}
        onChange={(value) => contactInformationDispatcher({ type: ContactInformationAction.SetName, payload: value })}
        onBlur={() => validateName()}
        value={name}
        error={nameError}
      />
      <FormInputField
        name="contactEmail"
        formInputType={FormInputEnum.Text}
        label={t('email')}
        isFilled={!!emailAddress}
        onChange={(value) => contactInformationDispatcher({ type: ContactInformationAction.SetEmail, payload: value })}
        onBlur={() => validateEmail()}
        value={emailAddress}
        error={emailAddressError}
      />
      <FormInputField
        name="contactPhone"
        formInputType={FormInputEnum.Text}
        label={t('phone-number')}
        isFilled={!!phoneNumber}
        onChange={(value) => contactInformationDispatcher({ type: ContactInformationAction.SetPhone, payload: value })}
        onBlur={() => validatePhoneNumber()}
        value={phoneNumber}
        error={phoneNumberError}
      />
    </FormStep>
  );
};

export default ContactInformationStep;
