import useBem from '@indicia/use-bem';

import PageNavbar from './page-components/page-navbar/page-navbar';
import PageContent from './page-components/page-content/page-content';

import { PageThemeEnum, PageType } from './types';

import './page.scss';

const Page = ({ children, className, theme = PageThemeEnum.Default }: PageType) => {
  const { bemClassName, bemClassNames } = useBem('page');

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, theme), className!!])}>
      <PageNavbar theme={theme} />
      <PageContent theme={theme}>{children}</PageContent>
    </div>
  );
};

Page.Main = PageContent;

export default Page;
