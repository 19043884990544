import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';
import { NavLink } from 'react-router-dom';

import Page from '../../../../shared/components/layout/page/page';
import { PageThemeEnum } from '../../../../shared/components/layout/page/types';
import MenuLogo from '../../../../shared/components/layout/page/page-components/page-navbar/components/menu-logo';
import { Button } from '../../../../shared/components';
import Help from '../../../../shared/components/help/help';

import './landing.scss';

const Landing: FC = () => {
  const { bemClassName, bemClassNames } = useBem('landing-page');
  const { t } = useTranslation();

  return (
    <Page theme={PageThemeEnum.BigCard}>
      <div className={bemClassName()}>
        <Help />
        <div className={bemClassName('video-container')}>
          <video
            className={bemClassNames([bemClassName('video'), bemClassName('video', 'hidden', true)])}
            autoPlay
            muted
            src="../assets/video/landingspagina.mp4"
            onPlaying={(e) => e.currentTarget.classList.remove('landing-page__video--hidden')}
            onEnded={(e) => e.currentTarget.classList.add('landing-page__video--hidden')}
          />
        </div>
        <div className={bemClassName('overlay')}>
          <div className={bemClassName('overlay-content')}>
            <MenuLogo theme={PageThemeEnum.BigCard} />
            <div className={bemClassName('overlay-content-text')}>
              <h1 className={bemClassName('title')}>
                <Trans
                  i18nKey="landing-page.title"
                  components={{
                    br: <br />
                  }}
                /></h1>
              <p>{t('landing-page.subtitle')}</p>
            </div>
            <NavLink to="/welcome">
              <Button>{t('landing-page.button')}</Button>
            </NavLink>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Landing;
