import { FC } from 'react';
import useBem from '@indicia/use-bem';

import useAccommodationContext from '../../../data/context/use-accommodation-context';

import './accommodation-detail-header.scss';

const AccommodationDetailHeader: FC = () => {
  const { accommodation } = useAccommodationContext();
  const { name } = accommodation ?? { name: '' };
  const { bemClassName } = useBem('accommodation-detail-header');

  return (
    <div className={bemClassName()}>
      <h1 className={bemClassName('title')}>{name}</h1>
    </div>
  );
};

export default AccommodationDetailHeader;
