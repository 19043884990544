import { createContext } from 'react';

import { BookingFormContextType, BookingType, RoomBookingType } from '../../types';
import PaymentOptionEnum from '../../steps/payment-step/PaymentOptionEnum';
import { ReducerActionType } from '../../../../shared/types/types';
import BookingAction from '../reducers/booking-reducer/booking-actions';
import ContactInformationAction from '../reducers/contact-information-reducer/contact-information-actions';
import DaySelectionAction from '../reducers/day-selection-reducer/day-selection-actions';

const storedFormStep = localStorage.getItem('formStep');

const BookingFormContext = createContext<BookingFormContextType>({
  loading: false,
  setLoading: (loading: boolean) => {
    throw new Error(`Not implemented yet, loading: ${loading}`);
  },
  formStep: storedFormStep ? Number.parseInt(storedFormStep, 10) : 1,
  handleFormStep: (step: number) => {
    throw new Error(`Not implemented yet, step: ${step}`);
  },
  allowNextStep: (step: number) => {
    throw new Error(`Not implemented yet, step: ${step}`);
  },
  handleNextStep: () => {
    throw new Error(`Not implemented yet`);
  },
  setFormError: (error: string) => {
    throw new Error(`Not implemented yet, error: ${error}`);
  },
  setMoreRooms: (value: boolean) => {
    throw new Error(`Not implemented yet, value: ${value}`);
  },
  setMoreRoomsId: (id?: string) => {
    throw new Error(`Not implemented yet, id: ${id}`);
  },
  bookedOverflowRooms: [],
  setBookedOverflowRooms: (value: RoomBookingType[]) => {
    throw new Error(`Not implemented yet, value: ${value}`);
  },
  paymentOption: PaymentOptionEnum.CREDIT_CARD,
  setPaymentOption: (value: string) => {
    throw new Error(`Not implemented yet, value: ${value}`);
  },
  bookingDispatcher: (action: ReducerActionType<BookingAction>) => {
    throw new Error(`Not implemented yet, type: ${action}`);
  },
  updateBooking: (booking: BookingType) => {
    throw new Error(`Not implemented yet, booking: ${booking}`);
  },
  contactInformation: {
    name: '',
    emailAddress: '',
    phoneNumber: '',
  },
  contactInformationDispatcher: (action: ReducerActionType<ContactInformationAction>) => {
    throw new Error(`Not implemented yet, type: ${action}`);
  },
  daySelection: {
    longerStay: false,
  },
  daySelectionDispatcher: (action: ReducerActionType<DaySelectionAction>) => {
    throw new Error(`Not implemented yet, type: ${action}`);
  },
});

export default BookingFormContext;
