import { Children, cloneElement } from 'react';
import useBem from '@indicia/use-bem';
import RadioItem from './radio-item';
import { RadioItemType, RadioListType } from '../../../types';

import './form-radio-list.scss';

const FormRadioList = ({ children, name, onChange, selectedValue, error }: RadioListType) => {
  const { bemClassName, bemClassNames } = useBem('radio-list');

  const childrenWithProps = Children.map(children, (child) =>
    cloneElement(child as React.ReactElement<RadioItemType>, { name, onChange, selectedValue }),
  );

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, 'error', !!error)])}>
      <div className={bemClassName('items')}>{childrenWithProps}</div>
      <span className={bemClassNames([bemClassName('error'), bemClassName('error', 'hide', !!error)])}>
        {error?.message}
      </span>
    </div>
  );
};

FormRadioList.Item = RadioItem;

export default FormRadioList;
