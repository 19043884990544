import { FC, useEffect } from 'react';
import useBem from '@indicia/use-bem';
import { useNavigate } from 'react-router-dom';

import Page from '../../../shared/components/layout/page/page';
import Form from '../../../shared/components/form/form';
import AccommodationDetails from '../../accommodations/components/accommodation-details/accommodation-details';
import {
  ContactInformationStep,
  ConfirmationStep,
  GuestInformationStep,
  RequireMoreRoomsStep,
  AccommodationSelectionStep,
  RequireMeetingRoomStep,
  PaymentStep,
} from '../steps';
import useAccommodationContext from '../../accommodations/data/context/use-accommodation-context';
import OverflowSelection from '../components/overflow-selection/overflow-selection';
import usePrimaryAccommodations from '../../accommodations/hooks/usePrimaryAccommodations';
import useOverflowAccommodations from '../../accommodations/hooks/useOverflowAccommodations';
import useBookingFormContext from '../data/context/use-booking-form-context';

const BookingForm: FC = () => {
  const navigate = useNavigate();
  const { bemClassName } = useBem('booking-form');
  const { data: primaryAccommodations, error: errorPrimary } = usePrimaryAccommodations();
  const { data: overflowAccommodations, error: errorSecondary } = useOverflowAccommodations();
  const { setAccommodation, setPrimaryAccommodations, setOverflowAccommodations } = useAccommodationContext();
  const { formError } = useBookingFormContext();

  useEffect(() => {
    if (primaryAccommodations) {
      setPrimaryAccommodations(primaryAccommodations);
    }
  }, [primaryAccommodations]);

  useEffect(() => {
    if (overflowAccommodations) {
      setOverflowAccommodations(overflowAccommodations);
    }
  }, [overflowAccommodations]);

  if (errorPrimary || errorSecondary) {
    navigate('/error');
  }

  return (
    <Page className={bemClassName()}>
      <Form id="booking-form">
        <ContactInformationStep />
        <GuestInformationStep />
        <RequireMeetingRoomStep />
        <AccommodationSelectionStep
          accommodations={primaryAccommodations ?? []}
          setSelectedAccommodation={setAccommodation}
        />
        <RequireMoreRoomsStep />
        <ConfirmationStep />
        <PaymentStep />
      </Form>
      <AccommodationDetails />
      <OverflowSelection />
      {formError && <div>{formError}</div>}
    </Page>
  );
};

export default BookingForm;
