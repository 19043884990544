import { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContextType } from '../../../../shared/types/types';
import bookingReducer from '../reducers/booking-reducer/booking-reducer';
import contactInformationReducer from '../reducers/contact-information-reducer/contact-information-reducer';
import daySelectionReducer from '../reducers/day-selection-reducer/day-selection-reducer';
import PaymentOptionEnum from '../../steps/payment-step/PaymentOptionEnum';
import {
  validateContactInformation,
  validateGuestInformation,
  validatePrimaryAccommodationBooking,
  validateReserveConferenceRoom,
} from '../utils/booking-validators';
import postContactInformation from '../services/contact-information-service';
import postPayment from '../services/payment-service';
import { confirm } from '../services/booking-service';
import BookingFormContext from './booking-form-context';
import { BookingProcessSteps } from '../utils/enums';
import { BookingType, RoomBookingType } from '../../types';
import BookingAction from '../reducers/booking-reducer/booking-actions';
import ContactInformationAction from '../reducers/contact-information-reducer/contact-information-actions';
import DaySelectionAction from '../reducers/day-selection-reducer/day-selection-actions';

const storedFormStep = localStorage.getItem('formStep');
const storedBooking = localStorage.getItem('booking') ? JSON.parse(localStorage.getItem('booking')!) : undefined;
const MAXIMUM_FORM_STEP = 7;
const DAY_ONE = '2025-06-24';
const DAY_TWO = '2025-06-25';

const BookingContextProvider: FC<ContextType> = ({ children = undefined }) => {
  const navigate = useNavigate();
  const [booking, bookingDispatcher] = useReducer(bookingReducer, storedBooking);
  const [contactInformation, contactInformationDispatcher] = useReducer(contactInformationReducer, {
    name: '',
    nameError: undefined,
    emailAddress: '',
    emailAddressError: undefined,
    phoneNumber: '',
    phoneNumberError: undefined,
  });
  const [daySelection, daySelectionDispatcher] = useReducer(daySelectionReducer, {
    longerStay: false,
    longerStayDescription: undefined,
    selectedDaysError: undefined,
  });
  const [moreRooms, setMoreRooms] = useState<boolean | undefined>();
  const [moreRoomsId, setMoreRoomsId] = useState<string | undefined>();
  const [bookedOverflowRooms, setBookedOverflowRooms] = useState<RoomBookingType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formStep, setFormStep] = useState<number>(1);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [paymentOption, setPaymentOption] = useState<PaymentOptionEnum>(PaymentOptionEnum.CREDIT_CARD);

  const determineInitializationStep = (initBooking: BookingType) => {
    const {
      contactInformation: ci,
      amountOfPeople: aop,
      reserveConferenceRoom: rcr,
      accommodationBookings: ab,
      bookingStatus,
    } = initBooking;
    if (bookingStatus !== 'Initialized') return BookingProcessSteps.Payment;

    const validations = [
      () => validateContactInformation(ci),
      () => validateGuestInformation(aop),
      () => validateReserveConferenceRoom(rcr),
      () => validatePrimaryAccommodationBooking(ab),
      () => Number.parseInt(storedFormStep ?? '0', 10) === BookingProcessSteps.ReviewAndConfirm,
    ];

    const failedValidationIndex = validations.findIndex((validation) => !validation());
    return failedValidationIndex !== -1 ? failedValidationIndex + 1 : validations.length + 1;
  };

  const handleFormStep = (step: number) => {
    localStorage.setItem('formStep', `${step}`);
    setFormStep(step);
  };

  const updateBooking = (updatedBooking: BookingType) => {
    const updatedBookingCopy = { ...updatedBooking };
    const {
      contactInformation: { name, emailAddress, phoneNumber },
      bookingStatus,
    } = updatedBookingCopy;

    if (name) contactInformationDispatcher({ type: ContactInformationAction.SetName, payload: name });
    if (emailAddress) contactInformationDispatcher({ type: ContactInformationAction.SetEmail, payload: emailAddress });
    if (phoneNumber) contactInformationDispatcher({ type: ContactInformationAction.SetPhone, payload: phoneNumber });

    if (emailAddress && phoneNumber) handleFormStep(2);
    if (bookingStatus !== 'Initialized') handleFormStep(MAXIMUM_FORM_STEP);

    updatedBookingCopy.from = DAY_ONE;
    updatedBookingCopy.to = DAY_TWO;

    bookingDispatcher({ type: BookingAction.SetBooking, payload: updatedBookingCopy });
  };

  const allowNextStep = (step: number): boolean => {
    const { amountOfPeople, accommodationBookings, reserveConferenceRoom } = booking;
    switch (step) {
      case BookingProcessSteps.ContactInformation:
        return validateContactInformation(contactInformation);
      case BookingProcessSteps.GuestInformation:
        return validateGuestInformation(amountOfPeople);
      case BookingProcessSteps.ConferenceRoomSelection:
        return validateReserveConferenceRoom(reserveConferenceRoom);
      case BookingProcessSteps.AccommodationsSelection:
        return validatePrimaryAccommodationBooking(accommodationBookings);
      default:
        return true;
    }
  };

  const handleContactInformationStep = async () => {
    setLoading(true);

    await postContactInformation(contactInformation)
      .then(() => {
        handleFormStep(formStep + 1);
      })
      .catch((response) => {
        const {
          data: { errors },
          status,
        } = response;
        if (status === 422) {
          if (errors.EmailAddress) {
            contactInformationDispatcher({
              type: ContactInformationAction.SetEmailError,
              payload: errors.EmailAddress[0],
            });
          }
          if (errors.PhoneNumber) {
            contactInformationDispatcher({
              type: ContactInformationAction.SetPhoneError,
              payload: errors.PhoneNumber[0],
            });
          }
        } else {
          navigate('/error');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirmPaymentStep = async () => {
    setLoading(true);

    await postPayment(paymentOption)
      .then(({ checkOutUrl }) => {
        window.location.href = checkOutUrl;
      })
      .catch(() => {
        navigate('/error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleConfirmBookingStep = async () => {
    setLoading(true);

    if (booking?.bookingStatus !== 'Initialized') {
      handleFormStep(formStep + 1);
      setLoading(false);
      return;
    }

    await confirm(booking!)
      .then((response) => {
        handleFormStep(formStep + 1);
        bookingDispatcher({ type: BookingAction.SetBooking, payload: response });
      })
      .catch(() => {
        navigate('/error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNextStep = async (): Promise<void> => {
    switch (formStep) {
      case BookingProcessSteps.ContactInformation:
        await handleContactInformationStep();
        break;
      case BookingProcessSteps.ReviewAndConfirm:
        await handleConfirmBookingStep();
        break;
      case BookingProcessSteps.Payment:
        await handleConfirmPaymentStep();
        break;
      case BookingProcessSteps.ConferenceRoomSelection:
      case BookingProcessSteps.GuestInformation:
      case BookingProcessSteps.AdditionalRoomSelection:
      case BookingProcessSteps.AccommodationsSelection:
      default:
        if (formStep < MAXIMUM_FORM_STEP) {
          handleFormStep(formStep + 1);
        }
    }
  };

  const initializeBooking = () => {
    if (storedBooking) {
      const { contactInformation: ci, longerStay } = storedBooking;
      contactInformationDispatcher({ type: ContactInformationAction.SetAll, payload: JSON.stringify(ci) });
      bookingDispatcher({ type: BookingAction.SetBooking, payload: storedBooking });
      daySelectionDispatcher({ type: DaySelectionAction.SetLongerStay, payload: longerStay });

      const initStep = determineInitializationStep(storedBooking);
      setFormStep(initStep);
    }
  };

  const adjustBookingDates = () => {
    const { longerStay, longerStayDescription } = daySelection;
    bookingDispatcher({ type: BookingAction.SetLongerStay, payload: longerStay });
    bookingDispatcher({ type: BookingAction.SetLongerStayDescription, payload: longerStayDescription });
  };

  useEffect(() => {
    if (daySelection) {
      adjustBookingDates();
    }
  }, [daySelection]);

  useEffect(() => {
    localStorage.setItem('booking', JSON.stringify(booking));
  }, [booking]);

  useEffect(() => {
    initializeBooking();
  }, []);

  const context = useMemo(
    () => ({
      loading,
      setLoading,
      formStep,
      handleFormStep,
      allowNextStep,
      handleNextStep,
      formError,
      setFormError,
      paymentOption,
      setPaymentOption,
      moreRooms,
      setMoreRooms,
      moreRoomsId,
      setMoreRoomsId,
      bookedOverflowRooms,
      setBookedOverflowRooms,
      booking,
      bookingDispatcher,
      updateBooking,
      contactInformation,
      contactInformationDispatcher,
      daySelection,
      daySelectionDispatcher,
    }),
    [formStep, contactInformation, moreRooms, bookedOverflowRooms, loading, booking, daySelection, paymentOption],
  );

  return <BookingFormContext.Provider value={context}>{children}</BookingFormContext.Provider>;
};

export default BookingContextProvider;
