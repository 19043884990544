import useBem from '@indicia/use-bem';

import { MenuLogoType } from './types';
import { PageThemeEnum } from '../../../types';

import './menu-logo.scss';

const MenuLogo = ({ theme = PageThemeEnum.Default }: MenuLogoType) => {
  const { bemClassName, bemClassNames } = useBem('menu-logo');
  const wmLogo = theme === PageThemeEnum.BigCard ? 'worldmeetings' : 'worldmeetings-dark';
  const roLogo = theme === PageThemeEnum.BigCard ? 'RO_Logo' : 'RO_Logo-dark';

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, theme)])}>
      <img
        src="assets/images/NATOhor_RGB.jpg"
        alt="NATO Logo"
      />
      <img
        src={`assets/images/${wmLogo}.svg`}
        alt="Worldmeetings logo"
      />
      <img
        src={`assets/images/${roLogo}.svg`}
        alt="Rijksoverheid logo"
      />
    </div>
  );
};

export default MenuLogo;
