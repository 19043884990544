import { FC } from 'react';
import useBem from '@indicia/use-bem';
import { AddressType } from '../../../accommodations/types/types';

import './booking-confirmation-item.scss';

type PropsType = {
  title: string;
  address: AddressType;
  phoneNumber: string;
  email: string;
  country: string;
};

const BookingConfirmationItem: FC<PropsType> = ({ title, address, phoneNumber, email, country }) => {
  const { bemClassName } = useBem('booking-confirmation-item');
  const { street, number, postalCode, city } = address;

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('title')}>
        <h4>{title}</h4>
      </div>
      <div className={bemClassName('details')}>
        <div className="contact-information">
          <span>{phoneNumber}</span>
          <span>{email}</span>
        </div>
        <div className="address">
          <span>{`${street} ${number}`}</span>
          <span>{`${postalCode} ${city}`}</span>
          <span>{country}</span>
        </div>
      </div>
    </div>
  );
};

export default BookingConfirmationItem;
