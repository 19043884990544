import { FC } from 'react';
import useBem from '@indicia/use-bem';

import { FormToggleType } from '../../types';

const FormToggle: FC<FormToggleType> = ({ name, label, onChange, value }) => {
  const { bemClassName, bemClassNames } = useBem('checkbox');

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, 'checked', value)])}>
      <input
        name={name}
        type="checkbox"
        id={`${name}-${value}`}
        className={bemClassName('input')}
        onChange={(event) => {
          const { checked } = event.target;
          onChange(checked);
        }}
      />
      <label
        htmlFor={`${name}-${value}`}
        className={bemClassName('label')}
      >
        {label}
      </label>
    </div>
  );
};

export default FormToggle;
