import { AccommodationType } from '../../types/types';

const getAccommodationById = (
  accommodationId: string,
  primaryAccommodations?: AccommodationType[],
  overflowAccommodations?: AccommodationType[],
): AccommodationType | undefined => {
  let accommodation = primaryAccommodations?.find(({ id }) => id === accommodationId);
  if (!accommodation) {
    accommodation = overflowAccommodations?.find(({ id }) => id === accommodationId);
  }

  return accommodation;
};

export default getAccommodationById;
