import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { AccommodationType } from '../types/types';
import { getOverflow } from '../data/services/accommodation-service';

const useOverflowAccommodations = (): UseQueryResult<AccommodationType[], Error> =>
  useQuery({
    queryKey: ['accommodations', 'overflow'],
    queryFn: () => getOverflow(),
  });

export default useOverflowAccommodations;
