import { ReducerActionType } from '../../../../../shared/types/types';
import { ContactInformationType, ContactInformationWithErrorsType } from '../../../types';
import ContactInformationAction from './contact-information-actions';

const contactInformationReducer = (
  state: ContactInformationType,
  action: ReducerActionType<ContactInformationAction>,
): ContactInformationWithErrorsType => {
  const { type, payload } = action;
  switch (type) {
    case ContactInformationAction.SetName:
      return {
        ...state,
        name: payload as string,
      };
    case ContactInformationAction.SetNameError:
      return {
        ...state,
        nameError: payload as string,
      };
    case ContactInformationAction.SetEmail:
      return {
        ...state,
        emailAddress: payload as string,
      };
    case ContactInformationAction.SetEmailError:
      return {
        ...state,
        emailAddressError: payload as string,
      };
    case ContactInformationAction.SetPhone:
      return {
        ...state,
        phoneNumber: payload as string,
      };
    case ContactInformationAction.SetPhoneError:
      return {
        ...state,
        phoneNumberError: payload as string,
      };
    case ContactInformationAction.SetAll:
      return {
        ...state,
        ...JSON.parse(payload as string),
      };
    default:
      throw Error('Action type not found');
  }
};

export default contactInformationReducer;
