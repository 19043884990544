import { FC, useState } from 'react';
import useBem from '@indicia/use-bem';

import { FormInputEnum, FormInputFieldType, InputSizeEnum } from '../../types';

const FormInputField: FC<FormInputFieldType> = ({
  name,
  formInputType,
  onChange,
  onBlur,
  value,
  required = false,
  label,
  placeholder = undefined,
  isFilled,
  isVisible = true,
  error,
  size = InputSizeEnum.Default,
  disabled = false,
}) => {
  const { bemClassName, bemClassNames } = useBem('form');
  const [isFocused, setIsFocused] = useState(false);
  const [isFilledState, setIsFilledState] = useState(false);

  return (
    <div
      className={bemClassNames([
        bemClassName('input-wrapper'),
        bemClassName('input-wrapper', 'hide', !isVisible),
        bemClassName('input-wrapper', 'error', !!error),
        bemClassName('input-wrapper', size),
        bemClassName(
          'input-wrapper',
          'filled',
          formInputType === FormInputEnum.Month ||
            formInputType === FormInputEnum.Date ||
            formInputType === FormInputEnum.DateTime ||
            isFilled ||
            isFilledState ||
            isFocused,
        ),
      ])}
    >
      <input
        type={formInputType}
        id={name}
        placeholder={placeholder}
        onBlurCapture={() => setIsFocused(false)}
        className={bemClassName('input')}
        onFocus={() => setIsFocused(true)}
        onChange={(changeEvent: any) => {
          const { value: v } = changeEvent.target;

          if (v !== '') setIsFilledState(true);
          else setIsFilledState(false);
          onChange(v);
        }}
        disabled={disabled}
        onBlur={onBlur}
        value={value}
      />
      <label
        htmlFor={name}
        className={bemClassName(
          'label',
          'filled',
          formInputType === FormInputEnum.Month ||
            formInputType === FormInputEnum.Date ||
            formInputType === FormInputEnum.DateTime ||
            isFilled ||
            isFilledState ||
            isFocused,
        )}
      >
        {label}
        {required && '*'}
      </label>
      {error && (
        <span className={bemClassNames([bemClassName('error'), bemClassName('error', 'hide', !!error)])}>{error}</span>
      )}
    </div>
  );
};

export default FormInputField;
