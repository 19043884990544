import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import useBem from '@indicia/use-bem';

import { ConfirmationType } from './types';

const ConfirmationRecord: FC<ConfirmationType> = ({ text, value, renderMode = 'text' }) => {
  const { bemClassName, bemClassNames } = useBem('confirmation-overview');

  return (
    <div className={bemClassName('record')}>
      <div className={bemClassName('record-title')}>{text}</div>
      <div className={bemClassNames([bemClassName('record-value'), bemClassName('record-value', renderMode)])}>
        {renderMode === 'text' ? (
          value
        ) : (
          <NumericFormat
            value={value}
            decimalSeparator="."
            thousandSeparator=","
            displayType="text"
            prefix="€"
          />
        )}
      </div>
    </div>
  );
};

export default ConfirmationRecord;
