import { FC } from 'react';
import useBem from '@indicia/use-bem';

import useAccommodationContext from '../../../data/context/use-accommodation-context';

import './accommodation-detail-gallery.scss';

const AccommodationDetailGallery: FC = () => {
  const { accommodation } = useAccommodationContext();
  const { resourceLinks } = accommodation ?? { resourceLinks: [] };
  const { bemClassName, bemClassNames } = useBem('accommodation-detail-gallery');
  const visibleResourceLinks = resourceLinks.sort((a, b) => a.id - b.id).slice(0, 2);

  return (
    <div className={bemClassNames([bemClassName()])}>
      {visibleResourceLinks.slice(0, 2).map(({ url }, index) => (
        <img
          key={url}
          className={bemClassName('image')}
          src={url}
          alt={`Hotel ${index + 1}`}
        />
      ))}
    </div>
  );
};

export default AccommodationDetailGallery;
