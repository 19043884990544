import { ReactNode } from 'react';

export type PageType = {
  children: ReactNode;
  className?: string;
  theme?: PageThemeEnum;
  backgroundImgUrl?: string;
};

export type PageContentType = {
  children: ReactNode;
  className?: string;
  theme?: PageThemeEnum;
};

export type PageNavbarType = {
  theme: PageThemeEnum;
};

export enum PageThemeEnum {
  Default = 'default',
  BigCard = 'big-card',
}
