import { FC, useState } from 'react';
import useBem from '@indicia/use-bem';

import { FormTextAreaType } from '../../types';

const FormTextArea: FC<FormTextAreaType> = ({
  name,
  label,
  required = false,
  amountOfRows = 4,
  isFilled,
  isVisible = true,
  error = undefined,
  placeholder,
  disabled,
  value,
  onChange,
}) => {
  const { bemClassName, bemClassNames } = useBem('form');
  const [isFocused, setIsFocused] = useState(false);
  const [isFilledState, setIsFilledState] = useState(false);

  return (
    <div
      className={bemClassNames([
        bemClassName('textarea-wrapper'),
        bemClassName('textarea-wrapper', 'hide', !isVisible),
        bemClassName('input-wrapper', 'error', !!error),
      ])}
    >
      <textarea
        id={name}
        onBlurCapture={() => setIsFocused(false)}
        className={bemClassName('input')}
        onFocus={() => setIsFocused(true)}
        onChangeCapture={(changeEvent: any) => {
          const { value: v } = changeEvent.target;

          if (v !== '') setIsFilledState(true);
          else setIsFilledState(false);
          onChange(v);
        }}
        disabled={disabled}
        rows={amountOfRows}
        placeholder={placeholder}
      >
        {value}
      </textarea>
      <label
        htmlFor={name}
        className={bemClassName('label', 'filled', isFilled || isFilledState || isFocused)}
      >
        {label}
        {required && '*'}
      </label>
      <span className={bemClassNames([bemClassName('error'), bemClassName('error', 'hide', !!error)])}>{error}</span>
    </div>
  );
};

export default FormTextArea;
