import { FC } from 'react';
import useBem from '@indicia/use-bem';
import { FormInputEnum, RadioItemType } from '../../../types';

import './radio-item.scss';

const RadioItem: FC<RadioItemType> = ({ name, label, value, onChange, selectedValue }) => {
  const { bemClassName, bemClassNames } = useBem('radio-item');

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, 'checked', selectedValue === value)])}>
      <input
        name={name}
        type={FormInputEnum.Radio}
        id={`${name}-${value}`}
        className={bemClassName('radio-input')}
        onChange={(event) => {
          const { value: v } = event.target;
          onChange!(v);
        }}
        value={value}
      />
      <label
        htmlFor={`${name}-${value}`}
        className={bemClassName('radio-label')}
      >
        {label}
      </label>
    </div>
  );
};

export default RadioItem;
