import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';
import { NumericFormat } from 'react-number-format';

import { ResourceLinkType } from '../../types/types';
import RoomAmountSelection from '../room-amount-selection/room-amount-selection';

import './room-card.scss';

export type RoomCardType = {
  title: string;
  description: string;
  price: number;
  displayPrice: boolean;
  amenities?: string[];
  onChange: (count: number) => void;
  resourceLinks: ResourceLinkType[];
  luxuryLevel: string;
  count: number;
  maxCount: number;
  onRoomCountChange?: () => void;
};

const RoomCard: FC<RoomCardType> = ({
  title,
  description,
  price,
  displayPrice,
  count,
  maxCount,
  onChange,
  luxuryLevel,
  resourceLinks,
  amenities = [],
  onRoomCountChange = undefined,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'room-card' });
  const { bemClassName } = useBem('room-card');
  const [activeImage, setActiveImage] = useState(0);

  const handleRoomCountChange = (newCount: number) => {
    onChange(newCount);
    if (onRoomCountChange) {
      onRoomCountChange();
    }
  };

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('image-carousel')}>
        <div
          className={bemClassName('image')}
          style={{ backgroundImage: `url(${resourceLinks[activeImage]?.url})` }}
        >
          <div className={bemClassName('image-buttons')}>
            {resourceLinks.map(({ url }, index) => (
              <button
                key={url}
                type="button"
                onClick={() => setActiveImage(index)}
                aria-label={`Image ${index + 1}`}
                className={index === activeImage ? bemClassName('active') : bemClassName('image-button')}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={bemClassName('content')}>
        <div className={bemClassName('top-content')}>
          <div className={bemClassName('room-description')}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <div className={bemClassName('room-details')}>
            {displayPrice && (
              <h3 className={bemClassName('price')}>
                <NumericFormat
                  value={price}
                  decimalSeparator="."
                  thousandSeparator=","
                  displayType="text"
                  prefix="€"
                />
                <p>{t('per-extra-room')}</p>
              </h3>
            )}
            <ul className={bemClassName('amenities')}>
              {amenities.map((amenity) => (
                <li
                  key={amenity}
                  className={bemClassName('amenity')}
                >
                  {amenity}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={bemClassName('bottom-content')}>
          {luxuryLevel === 'DelegationRoom' ? (
            <RoomAmountSelection
              roomCount={count}
              maxRoomCount={maxCount}
              decreaseRoomCount={() => handleRoomCountChange(count - 1)}
              increaseRoomCount={() => handleRoomCountChange(count + 1)}
            />
          ) : (
            <RoomAmountSelection
              roomCount={luxuryLevel === 'HeadOfDelegation' ? 1 : 2}
              maxRoomCount={luxuryLevel === 'HeadOfDelegation' ? 1 : 2}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
