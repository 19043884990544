import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormStep from '../../../../shared/components/form/react-hook-form-components/form-step/form-step';
import { FormStepType } from '../../../../shared/components/form/types';
import ConfirmationOverview from '../../components/confirmation-overview/confirmation-overview';

const GuestInformationStep: FC<FormStepType> = ({ stepNumber = 1, currentStep = 1 }) => {
  const { t } = useTranslation('common', { keyPrefix: 'booking-form.confirmation' });

  return (
    <FormStep
      title={t(`title`)}
      description={t(`description`)}
      stepNumber={stepNumber}
      currentStep={currentStep}
    >
      <ConfirmationOverview />
    </FormStep>
  );
};

export default GuestInformationStep;
