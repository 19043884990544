import React, { FC } from 'react';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { Icon } from '@indicia/component-library';

import { AccommodationType } from '../../types/types';
import CardBase from './card-base';
import CardType from './card-type';

type AccommodationCardType = {
  accommodation: AccommodationType;
  onHandleClick: (accommodation: AccommodationType) => void;
};

const AccommodationCard: FC<AccommodationCardType> = ({ accommodation, onHandleClick }) => {
  const { t } = useTranslation();
  const { bemClassName } = useBem('accommodation-card');
  const { name, resourceLinks, isDisabled } = accommodation;

  return (
    <CardBase
      image={resourceLinks[0]?.url}
      title={name}
      type={CardType.Accommodation}
      onClick={() => onHandleClick(accommodation)}
      disabled={isDisabled}
    >
      {isDisabled ? (
        <div className={bemClassName('disabled-button')}>
          <div className={bemClassName('room-list')}>
            <span className={bemClassName('room-item')}>{t('accommodation-card.not-available')}</span>
          </div>
        </div>
      ) : (
        <div
          className={bemClassName('room-select-button')}
          title={t('arranged-hotels.select-room')}
        >
          {t('booking.accommodation-card.select-rooms')}
          <Icon
            iconName="arrow-right"
            width={19}
            height={8}
          />
        </div>
      )}
    </CardBase>
  );
};

export default AccommodationCard;
