import { ReactNode, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';

import RoomCard from '../../../../accommodations/components/room-card/room-card';

import './overflow-room-selection-list.scss';
import useBookingFormContext from '../../../data/context/use-booking-form-context';

type PropType = {
  children: ReactNode[];
};

const OverflowRoomSelectionList = ({ children }: PropType) => {
  const { bemClassName } = useBem('overflow-room-selection-list');

  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPricePrimary, setTotalPricePrimary] = useState(0);
  const [totalPriceExtra, setTotalPriceExtra] = useState(0);
  const { booking, bookedOverflowRooms } = useBookingFormContext();

  const calculateTotalPrice = (): number => {
    const { accommodationBookings } = booking!;
    return accommodationBookings.reduce((acc, { roomTypeBookings }) => {
      const hasHeadOfDelegationRoom = roomTypeBookings.some(
        ({ luxuryLevel }) => luxuryLevel === 'HeadOfDelegation'
      );

      if (!hasHeadOfDelegationRoom) {
        return acc;
      }

      const totalForAccommodation = roomTypeBookings.reduce(
        (accInner, { roomCount, pricePerNight }) => accInner + roomCount * pricePerNight,
        0,
      );

      return acc + totalForAccommodation;
    }, 0);
  };

  useEffect(() => {
    setTotalPricePrimary(calculateTotalPrice());
  }, []);

  useEffect(() => {
    let total = 0;
    bookedOverflowRooms.forEach((roomType) => {
      total += roomType.roomCount * roomType.pricePerNight
      setTotalPriceExtra(total);
    });
  }, [bookedOverflowRooms]);

  useEffect(() => {
    setTotalPrice(totalPricePrimary + totalPriceExtra);
  }, [totalPriceExtra, totalPricePrimary]);

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('header')}>
        <h3>
          {t('overflow-selection.total-price')}:
          <NumericFormat
            value={totalPrice}
            decimalSeparator="."
            thousandSeparator=","
            displayType="text"
            prefix="€"
          />
        </h3>
      </div>
      <div className={bemClassName('room-list')}>{children}</div>
      <div className={bemClassName('footer')}>
        <h4>
          {t('overflow-selection.total-price-extra')}:
          <NumericFormat
            value={totalPriceExtra}
            decimalSeparator="."
            thousandSeparator=","
            displayType="text"
            prefix="€"
          />
        </h4>
        <h4>
          {t('overflow-selection.total-price-primary')}:
          <NumericFormat
            value={totalPricePrimary}
            decimalSeparator="."
            thousandSeparator=","
            displayType="text"
            prefix="€"
          />
        </h4>
        <h3>
          {t('overflow-selection.total-price')}:
          <NumericFormat
            value={totalPrice}
            decimalSeparator="."
            thousandSeparator=","
            displayType="text"
            prefix="€"
          />
        </h3>
      </div>
    </div>
  );
};

OverflowRoomSelectionList.Item = RoomCard;

export default OverflowRoomSelectionList;
