import useBem from '@indicia/use-bem';

import { PageContentType, PageThemeEnum } from '../../types';
import { Container } from '../../../../index';

import './page-content.scss';

const PageContent = ({ className, children, theme }: PageContentType) => {
  const { bemClassName, bemClassNames } = useBem('page-content');

  return (
    <main className={bemClassNames([bemClassName('content'), bemClassName('content', className, !!className)])}>
      <Container theme={theme}>
        {theme === PageThemeEnum.BigCard ? <div className={bemClassName('big-card')}>{children} </div> : children}
      </Container>
    </main>
  );
};

export default PageContent;
