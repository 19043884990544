import { FC } from 'react';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { Icon } from '@indicia/component-library';

import CardBase from './card-base';
import useBookingFormContext from '../../../bookings/data/context/use-booking-form-context';
import CardType from './card-type';

import './booked-accommodation-card.scss';

const AccommodationCard: FC = () => {
  const { t } = useTranslation();
  const { setMoreRooms, setMoreRoomsId } = useBookingFormContext();
  const { bemClassName } = useBem('accommodation-card');

  return (
    <CardBase
      image="/assets/images/map.svg"
      title={t('accommodation-card.need-more-rooms')}
      type={CardType.MoreRooms}
      onClick={() => {
        setMoreRoomsId(undefined);
        setMoreRooms(true);
      }}
    >
      <div
        className={bemClassName('room-select-button')}
        title={t('arranged-hotels.select-room')}
      >
        {t('booking.accommodation-card.select-rooms')}
        <Icon
          iconName="arrow-right"
          width={19}
          height={8}
        />
      </div>
    </CardBase>
  );
};

export default AccommodationCard;
