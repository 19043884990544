import http from '../../../../shared/utils/http-client';
import { AccommodationType } from '../../types/types';

const getPrimaries = async (): Promise<AccommodationType[]> => {
  const { data } = await http.get(`accommodations/primary`);
  return data;
};

const getOverflow = async (): Promise<AccommodationType[]> => {
  const { data } = await http.get(`accommodations/overflow`);
  return data;
};

export { getPrimaries, getOverflow };
