import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';
import IconButton from '../../../../shared/components/icon-button/icon-button';

import './room-amount-selection.scss';

type RoomAmountSelectionType = {
  roomCount: number;
  maxRoomCount: number;
  decreaseRoomCount?: (count: number) => void;
  increaseRoomCount?: (count: number) => void;
};

const RoomAmountSelection: FC<RoomAmountSelectionType> = ({
  roomCount,
  maxRoomCount,
  decreaseRoomCount = undefined,
  increaseRoomCount = undefined,
}) => {
  const { t } = useTranslation();
  const { bemClassName } = useBem('room-amount-selection');

  return (
    <div className={bemClassName()}>
      <IconButton
        label={t('room-selection.minus')}
        onClick={decreaseRoomCount ? () => decreaseRoomCount(roomCount) : () => {}}
        iconName="minus"
        iconHeight={2}
        disabled={!decreaseRoomCount || roomCount === 0}
      />
      <div className={bemClassName('room-counter')}>
        {roomCount}
        {decreaseRoomCount && ` / ${maxRoomCount}`}
      </div>
      <IconButton
        label={t('room-selection.plus')}
        onClick={increaseRoomCount ? () => increaseRoomCount(roomCount) : () => {}}
        iconName="plus"
        disabled={!increaseRoomCount || roomCount >= maxRoomCount}
      />
    </div>
  );
};

export default RoomAmountSelection;
