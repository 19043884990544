import { createBrowserRouter } from 'react-router-dom';

import App from './app';
import ErrorPage from './features/error/pages/error-page';
import NotFound from './features/error/pages/not-found';
import ProtectedRoute from './features/auth/protected-route';
import Auth0ProviderWithHistory from './features/auth/auth0-provider-with-history';
import BookingForm from './features/bookings/pages/booking-form';
import Landing from './features/content/pages/landing/landing';
import Welcome from './features/content/pages/welcome/welcome';
import BookingConfirmation from './features/bookings/pages/bookin-confirmation/booking-confirmation';

const protectedRoutes = [
  {
    path: '/create-booking',
    element: <BookingForm />,
  },
  {
    path: '/booking-confirmation',
    element: <BookingConfirmation />,
  },
  {
    path: '/welcome',
    element: <Welcome />,
  },
];

const router: {
  path: string;
  element: JSX.Element;
  children?: { path: string; element: JSX.Element }[] | undefined;
  errorElement?: JSX.Element | undefined;
}[] = [
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '/',
    element: <Landing />,
  },
  {
    path: '/',
    element: (
      <Auth0ProviderWithHistory>
        <ProtectedRoute component={App} />
      </Auth0ProviderWithHistory>
    ),
    children: protectedRoutes,
    errorElement: <ErrorPage />,
  },
];

export default createBrowserRouter(router);
