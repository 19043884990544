import useBem from '@indicia/use-bem';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { AccommodationDetailRoomListType } from '../types';
import RoomCard from '../../room-card/room-card';

import './accommodation-detail-room-list.scss';
import useAccommodationContext from '../../../data/context/use-accommodation-context';

const AccommodationDetailRoomList = ({ children }: AccommodationDetailRoomListType) => {
  const { bemClassName } = useBem('accommodation-detail-room-list');
  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);
  const { accommodation, delegationRoomCount } = useAccommodationContext();

  useEffect(() => {
    let total = 0;
    accommodation?.roomTypes.forEach((roomType) => {
      switch (roomType.luxuryLevel) {
        case 'HeadOfDelegation':
          total += roomType.pricePerNight;
          break;
        case 'MinistersSuite':
          total += 2 * roomType.pricePerNight
          break;
        case 'DelegationRoom':
          total += delegationRoomCount * roomType.pricePerNight;
          break;
        default:
          break;
      }
      setTotalPrice(total);
    });
  }, [delegationRoomCount]);

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('header')}>
        <h2>{t('accommodation-details.room-title')}</h2>
        <h3>
          {t('accommodation-details.room-total-price')}:
          <NumericFormat
            value={totalPrice}
            decimalSeparator="."
            thousandSeparator=","
            displayType="text"
            prefix="€"
          />
        </h3>
      </div>
      <div className={bemClassName('header')}>
        <p>{t('accommodation-details.room-subtitle')}</p>
      </div>
      <div className={bemClassName('room-list')}>{children}</div>
      <div className={bemClassName('footer')}>
        <h3>
          {t('accommodation-details.room-total-price')}:
          <NumericFormat
            value={totalPrice}
            decimalSeparator="."
            thousandSeparator=","
            displayType="text"
            prefix="€"
          />
        </h3>
      </div>
    </div>
  );
};

AccommodationDetailRoomList.Item = RoomCard

export default AccommodationDetailRoomList;
