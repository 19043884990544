import { RoomType } from '../../../accommodations/types/types';
import { RoomBookingType } from '../../types';

const luxuryLevelOrder = {
  HeadOfDelegation: 1,
  MinistersSuite: 2,
  DelegationRoom: 3,
};

const roomTypeSorter = (a: RoomType, b: RoomType) =>
  luxuryLevelOrder[a.luxuryLevel as keyof typeof luxuryLevelOrder] -
  luxuryLevelOrder[b.luxuryLevel as keyof typeof luxuryLevelOrder];

const roomBookingTypeSorter = (a: RoomBookingType, b: RoomBookingType) =>
  luxuryLevelOrder[a.luxuryLevel as keyof typeof luxuryLevelOrder] -
  luxuryLevelOrder[b.luxuryLevel as keyof typeof luxuryLevelOrder];

export { roomTypeSorter, roomBookingTypeSorter };
