import { useEffect, useState } from 'react';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';

import { RoomType } from '../../../accommodations/types/types';
import OverflowRoomSelectionList from './overflow-room-selection/overflow-room-selection-list';
import BookingAction from '../../data/reducers/booking-reducer/booking-actions';
import useAccommodationContext from '../../../accommodations/data/context/use-accommodation-context';
import LuxuryLevel from '../../../accommodations/data/utils/luxury-level';
import useBookingFormContext from '../../data/context/use-booking-form-context';
import Page from '../../../../shared/components/layout/page/page';
import { RoomBookingType } from '../../types';
import { Button } from '../../../../shared/components';

import './overflow-selection.scss';

const OverflowSelection = () => {
  const { t } = useTranslation('common', { keyPrefix: 'overflow-selection' });
  const { bemClassName } = useBem('overflow-selection');
  const [roomCounters, setRoomCounters] = useState<{ [key in string]: number }>({});
  const { overflowAccommodations } = useAccommodationContext();
  const { moreRooms, booking, bookingDispatcher, setMoreRooms, moreRoomsId, setMoreRoomsId, setBookedOverflowRooms } =
    useBookingFormContext();
  const { accommodationBookings } = booking!;

  const availableRooms = overflowAccommodations
    .flatMap((accommodation) =>
      accommodation.roomTypes.find(({ luxuryLevel }) => luxuryLevel === LuxuryLevel.DelegationRoom),
    )
    .filter((room) => room !== undefined) as RoomType[];

  useEffect(() => {
    const overflowBookings = accommodationBookings.filter(({ roomTypeBookings }) => roomTypeBookings.length === 1);
    const counters: { [key in string]: number } = {};
    overflowBookings.forEach(({ roomTypeBookings }) => {
      counters[roomTypeBookings[0].roomId] = roomTypeBookings[0].roomCount;
    });

    setRoomCounters(counters);
  }, []);

  const getAccommodationIdByRoomType = (roomId: string) =>
    overflowAccommodations.find((accommodation) => accommodation.roomTypes.find(({ id }) => id === roomId))!.id;

  useEffect(() => {
    const booked = Object.keys(roomCounters).map(
      (key) =>
        ({
          roomId: key,
          roomCount: roomCounters[key],
          pricePerNight: availableRooms.find(({ id }) => id === key)?.pricePerNight || 0,
          luxuryLevel: LuxuryLevel.DelegationRoom,
        } as RoomBookingType),
    );
    setBookedOverflowRooms(booked);
  }, [roomCounters]);

  const handleSubmit = () => {
    const accommodations = Object.keys(roomCounters).map((key) => ({
      accommodationId: getAccommodationIdByRoomType(key),
      roomTypeBookings: [
        {
          roomId: key,
          roomCount: roomCounters[key],
          luxuryLevel: LuxuryLevel.DelegationRoom,
          pricePerNight: availableRooms.find(({ id }) => id === key)?.pricePerNight,
        },
      ],
    }));

    const activeAccommodations = accommodations.filter(({ roomTypeBookings }) => roomTypeBookings[0].roomCount !== 0);
    const inactiveAccommodations = accommodations.filter(({ roomTypeBookings }) => roomTypeBookings[0].roomCount === 0);

    bookingDispatcher({ type: BookingAction.SetAccommodationBookings, payload: activeAccommodations });
    bookingDispatcher({ type: BookingAction.RemoveAccommodationBookings, payload: inactiveAccommodations });
    setMoreRoomsId(undefined);
    setMoreRooms(false);
  };

  return (
    <AnimatePresence>
      {moreRooms && (
        <motion.div
          initial={{ top: '100vh' }}
          animate={{ top: 0 }}
          exit={{ top: '100vh' }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
          className={bemClassName()}
        >
          <Page>
            <div className={bemClassName('header')}>
              <h2>{t('title')}</h2>
              <p>{t('description')}</p>
            </div>

            <OverflowRoomSelectionList>
              {overflowAccommodations.map(
                ({ id: accommodationId, name, description, roomTypes, resourceLinks: accommodationResourceLinks }) => {
                  const primaryAccommodation = accommodationBookings.find(
                    ({ roomTypeBookings }) => roomTypeBookings.length > 1,
                  );

                  const {
                    luxuryLevel,
                    amenities,
                    id,
                    pricePerNight,
                    resourceLinks: roomResourceLinks,
                    available,
                  } = roomTypes[0];

                  const combinedResourceLinks = [];

                  if (accommodationResourceLinks && accommodationResourceLinks.length > 0) {
                    combinedResourceLinks.push(accommodationResourceLinks[0]);
                  }

                  if (roomResourceLinks && roomResourceLinks.length > 0) {
                    combinedResourceLinks.push(roomResourceLinks[0]);
                  }

                  if (moreRoomsId !== undefined && accommodationId !== moreRoomsId) return null;
                  if (primaryAccommodation && primaryAccommodation?.accommodationId === accommodationId) return null;

                  return (
                    <OverflowRoomSelectionList.Item
                      key={id}
                      title={name}
                      description={description}
                      price={pricePerNight}
                      displayPrice
                      amenities={amenities.map((amenity) => amenity)}
                      luxuryLevel={luxuryLevel}
                      count={roomCounters[id] ?? 0}
                      resourceLinks={combinedResourceLinks}
                      maxCount={available}
                      onChange={(count) => {
                        if (count >= 0) {
                          setRoomCounters({
                            ...roomCounters,
                            [id]: count,
                          });
                        }
                      }}
                    />
                  );
                },
              )}
            </OverflowRoomSelectionList>
            <div className={bemClassName('footer')}>
              <Button
                type="button"
                onClick={() => setMoreRooms(false)}
              >
                {t('close')}
              </Button>
              <Button
                type="button"
                onClick={() => handleSubmit()}
              >
                {t('confirm')}
              </Button>
            </div>
          </Page>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OverflowSelection;
