import { FC, ReactNode } from 'react';
import useBem from '@indicia/use-bem';

import CardType from './card-type';

import './card-base.scss';

type PropType = {
  image: string;
  title: string;
  children: ReactNode | ReactNode[];
  type?: CardType;
  onClick?: () => void;
  disabled?: boolean;
};

const AccommodationCard: FC<PropType> = ({
  image,
  title,
  children = undefined,
  type = CardType.Accommodation,
  onClick = undefined,
  disabled = false,
}) => {
  const { bemClassName, bemClassNames } = useBem('accommodation-card');

  return (
    <button
      type="button"
      className={bemClassNames([bemClassName(), bemClassName(null, type), bemClassName(null, 'disabled', disabled)])}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      <div
        className={bemClassName('background')}
        style={{ backgroundImage: `url('${image}')` }}
      />
      <div className={bemClassName('content')}>
        <h4>{title}</h4>
        {children}
      </div>
    </button>
  );
};

export default AccommodationCard;
