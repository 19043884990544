import { FC } from 'react';
import useBem from '@indicia/use-bem';
import { Trans, useTranslation } from 'react-i18next';

import Page from '../../../../shared/components/layout/page/page';
import usePrimaryAccommodations from '../../../accommodations/hooks/usePrimaryAccommodations';
import useOverflowAccommodations from '../../../accommodations/hooks/useOverflowAccommodations';
import useBooking from '../../data/hooks/useBooking';
import BookingConfirmationItem from '../../components/booking-confirmation-item/booking-confirmation-item';
import getAccommodationById from '../../../accommodations/data/utils/get-accommodation-by-id';
import { Loader } from '../../../../shared/components';

import './booking-confirmation.scss';

const BookingConfirmation: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'booking-confirmation' });
  const { t: tContact } = useTranslation('common', { keyPrefix: 'contact-information' });
  const { bemClassName } = useBem('booking-confirmation');
  const { data: primaryAccommodations, isLoading: primaryIsLoading } = usePrimaryAccommodations();
  const { data: overflowAccommodations, isLoading: overflowIsLoading } = useOverflowAccommodations();
  const { data: booking, isLoading: bookingIsLoading } = useBooking();

  if (primaryIsLoading || overflowIsLoading || bookingIsLoading) {
    return <Loader />;
  }

  const { accommodationBookings } = booking!;

  const renderEmptySlot = () => (
    <BookingConfirmationItem
      title=""
      address={{
        street: '',
        number: '',
        postalCode: '',
        city: '',
      }}
      phoneNumber=""
      email=""
      country=""
    />
  );

  return (
    <Page className={bemClassName()}>
      <div className={bemClassName('header')}>
        <h1>{t('title')}</h1>
        <div>
          <Trans i18nKey="booking-confirmation.description" />
        </div>
      </div>
      <div className={bemClassName('footer')}>
        <div className={bemClassName('information-panel')}>
          <BookingConfirmationItem
            address={{
              street: tContact('address.street'),
              number: tContact('address.number'),
              postalCode: tContact('address.postalCode'),
              city: tContact('address.city'),
            }}
            title={tContact('title')}
            email={tContact('email')}
            phoneNumber={tContact('phone-number')}
            country={tContact('country')}
          />
          {accommodationBookings.map(({ accommodationId }) => {
            const accommodation = getAccommodationById(accommodationId, primaryAccommodations, overflowAccommodations);
            if (!accommodation) return null;

            const { address, name, email, phoneNumber } = accommodation;

            return (
              <BookingConfirmationItem
                key={name}
                address={address}
                title={name}
                email={email}
                phoneNumber={phoneNumber}
                country={tContact('country')}
              />
            );
          })}
          {accommodationBookings.length === 1 && renderEmptySlot()}
          {accommodationBookings.length === 3 && (
            <>
              {renderEmptySlot()}
              {renderEmptySlot()}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default BookingConfirmation;
