import { AccommodationBookingType, BookingType } from '../../../types';
import { ReducerActionType } from '../../../../../shared/types/types';
import BookingAction from './booking-actions';

/**
 * Handles the accommodation bookings within the booking state.
 * It updates the existing accommodation bookings array by either adding new bookings
 * or updating existing ones based on the accommodation ID.
 *
 * @param {BookingType} state - The current state of the booking.
 * @param {AccommodationBookingType[]} newBookings - An array of new or updated accommodation bookings.
 * @returns {AccommodationBookingType[]} The updated array of accommodation bookings.
 */
const handleAccommodationBookings = (
  state: BookingType,
  newBookings: AccommodationBookingType[],
): AccommodationBookingType[] => {
  const { accommodationBookings } = state;
  const updatedBookings = [...accommodationBookings];
  newBookings.forEach((newBooking) => {
    const { accommodationId: newBookingAccommodationId, roomTypeBookings } = newBooking;

    // Check if the Accommodation is a Primary Accommodation, as it should always be replaced with the new Primary Accommodation
    const index =
      roomTypeBookings.length > 1
        ? 0
        : updatedBookings.findIndex(({ accommodationId }) => accommodationId === newBookingAccommodationId);

    if (index === -1 || updatedBookings.length === 0) {
      updatedBookings.push(newBooking);
    } else {
      updatedBookings[index] = newBooking;
    }
  });

  return updatedBookings;
};

/**
 * Handles the accommodation bookings within the booking state that need to be removed.
 * It updates the existing accommodation bookings array by either adding new bookings
 * or updating existing ones based on the accommodation ID.
 *
 * @param {BookingType} state - The current state of the booking.
 * @param {AccommodationBookingType[]} removeBookings - An array of new or updated accommodation bookings.
 * @returns {AccommodationBookingType[]} The updated array of accommodation bookings.
 */
const handleRemoveAccommodationBookings = (
  state: BookingType,
  removeBookings: AccommodationBookingType[],
): AccommodationBookingType[] => {
  const { accommodationBookings } = state;
  const updatedBookings = [...accommodationBookings];
  removeBookings.forEach((booking) => {
    const { accommodationId: newBookingAccommodationId } = booking;
    const index = updatedBookings.findIndex(({ accommodationId }) => accommodationId === newBookingAccommodationId);
    if (index !== -1) {
      updatedBookings.splice(index, 1);
    }
  });

  return updatedBookings;
};

/**
 * The main reducer function for booking-related actions. It updates the booking state
 * based on the dispatched action type and payload.
 *
 * @param {BookingType} state - The current state of the booking.
 * @param {ReducerActionType<BookingAction>} action - The action dispatched to the reducer.
 * @returns {BookingType} The updated booking state.
 * @throws {Error} Throws an error if the action type is not found.
 */
const bookingReducer = (state: BookingType, action: ReducerActionType<BookingAction>): BookingType => {
  const { type, payload } = action;
  switch (type) {
    case BookingAction.SetAmountOfPeople:
      return {
        ...state,
        amountOfPeople: payload as number,
      };
    case BookingAction.SetFrom:
      return {
        ...state,
        from: payload as string,
      };
    case BookingAction.SetTo:
      return {
        ...state,
        to: payload as string,
      };
    case BookingAction.SetReserveConferenceRoom:
      return {
        ...state,
        reserveConferenceRoom: payload as boolean,
      };
    case BookingAction.SetLongerStay:
      return {
        ...state,
        longerStay: payload as boolean,
      };
    case BookingAction.SetLongerStayDescription:
      return {
        ...state,
        longerStayDescription: payload as string,
      };
    case BookingAction.SetBooking:
      return {
        ...state,
        ...(payload as BookingType),
      };
    case BookingAction.SetContactWithHotel:
      return {
        ...state,
        contactWithHotel: payload as boolean,
      };
    case BookingAction.SetAccommodationBookings:
      return {
        ...state,
        accommodationBookings: handleAccommodationBookings(state, payload as AccommodationBookingType[]),
      };
    case BookingAction.RemoveAccommodationBookings:
      return {
        ...state,
        accommodationBookings: handleRemoveAccommodationBookings(state, payload as AccommodationBookingType[]),
      };
    default:
      throw Error('Action type not found');
  }
};

export default bookingReducer;
