import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormStep from '../../../../shared/components/form/react-hook-form-components/form-step/form-step';
import { FormStepType } from '../../../../shared/components/form/types';
import { AccommodationType } from '../../../accommodations/types/types';
import AccommodationCardList from '../../../accommodations/components/accommodation-card-list/accommodation-card-list';

type AccommodationSelectionStepType = {
  accommodations: AccommodationType[];
  setSelectedAccommodation: (accommodation: AccommodationType) => void;
} & FormStepType;

const AccommodationSelectionStep: FC<AccommodationSelectionStepType> = ({
  accommodations,
  setSelectedAccommodation,
  stepNumber = 1,
  currentStep = 1,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'booking-form.accommodation-selection' });

  return (
    <FormStep
      title={t(`title`)}
      description={t(`description`)}
      stepNumber={stepNumber}
      currentStep={currentStep}
    >
      {accommodations && (
        <AccommodationCardList>
          {accommodations?.map((accommodation) => (
            <AccommodationCardList.Item
              key={accommodation.id}
              accommodation={accommodation}
              onHandleClick={(selectedAccommodation) => setSelectedAccommodation(selectedAccommodation)}
            />
          ))}
        </AccommodationCardList>
      )}
    </FormStep>
  );
};

export default AccommodationSelectionStep;
