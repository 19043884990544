import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from './shared/localization/en-gb/common.json';

const resources = {
  en: { common: commonEn },
};

const detection = {
  order: ['localStorage', 'navigator'],
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection,
    supportedLngs: ['en'],
    fallbackLng: 'en',
    defaultNS: 'common',
    ns: ['common'],
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

document.getElementsByTagName('html')[0].setAttribute('lang', i18n.language);

export default i18n;
