import useBem from '@indicia/use-bem';

import { Container } from '../../../../index';
import Help from '../../../../help/help';
import MenuLogo from './components/menu-logo';
import { PageNavbarType, PageThemeEnum } from '../../types';

import './page-navbar.scss';

const PageNavbar = ({ theme }: PageNavbarType) => {
  const { bemClassName, bemClassNames } = useBem('navbar');

  return (
    <nav className={bemClassNames([bemClassName(), bemClassName(null, theme)])}>
      <Container>
        <div className={bemClassName('items')}>{theme !== PageThemeEnum.BigCard && <MenuLogo />}</div>
        <Help />
      </Container>
    </nav>
  );
};

export default PageNavbar;
