import { FC, ReactNode } from 'react';
import useBem from '@indicia/use-bem';

import { ProgressBarType } from './types';

import './progress-bar.scss';

const ProgressBar: FC<ProgressBarType> = ({ total, current, onStepChange }) => {
  const { bemClassName, bemClassNames } = useBem('progress-bar');
  const percentage = Math.round(((current - 1) / (total - 1)) * 100);

  const progressBarNumbers: ReactNode[] = [];

  for (let i = 0; i < total; i += 1) {
    progressBarNumbers.push(
      <button
        key={i}
        type="button"
        title={`Step ${i + 1}`}
        className={bemClassNames([
          bemClassName('number'),
          bemClassName('number', i === current - 1 ? 'active' : ''),
          bemClassName('number', i < current - 1 ? 'completed' : ''),
        ])}
        onClick={() => {
          if (i < current) onStepChange(i + 1);
        }}
      >
        {i + 1 < current ? '✔' : i + 1}
      </button>,
    );
  }

  return (
    <div className={bemClassName()}>
      <div
        className={bemClassName('bar')}
        style={{ width: `${percentage}%` }}
      />
      <div className={bemClassName('numbers')}>{progressBarNumbers}</div>
    </div>
  );
};

export default ProgressBar;
