import { AxiosResponse } from 'axios';

import http from '../../../../shared/utils/http-client';
import PaymentOptionEnum from '../../steps/payment-step/PaymentOptionEnum';
import { PaymentRequestType, PaymentResponseType } from '../../types';

const post = async (paymentOption: PaymentOptionEnum): Promise<PaymentResponseType> => {
  const { data } = await http.post<PaymentRequestType, AxiosResponse<PaymentResponseType>>('payments', {
    paymentType: paymentOption,
  });

  return data;
};

export default post;
