import http from '../../../../shared/utils/http-client';
import { BookingType } from '../../types';

const get = async (): Promise<BookingType> => {
  const { data } = await http.get('bookings');
  return data;
};

const confirm = async (booking: BookingType): Promise<BookingType> => {
  const { data } = await http.post('bookings/confirm', booking);
  return data;
};

export { get, confirm };
