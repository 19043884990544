import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { AccommodationType } from '../types/types';
import { getPrimaries } from '../data/services/accommodation-service';

const usePrimaryAccommodations = (): UseQueryResult<AccommodationType[], Error> =>
  useQuery({
    queryKey: ['accommodations', 'primary'],
    queryFn: () => getPrimaries(),
  });

export default usePrimaryAccommodations;
