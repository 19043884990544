import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';
import { Icon } from '@indicia/component-library';

import CardBase from './card-base';
import { AccommodationType } from '../../types/types';
import { AccommodationBookingType } from '../../../bookings/types';
import { roomBookingTypeSorter } from '../../../bookings/data/utils/room-type-sorter';
import CardType from './card-type';

import './booked-accommodation-card.scss';

type AccommodationCardType = {
  accommodation: AccommodationType;
  accommodationBooking: AccommodationBookingType;
  onHandleClick: (accommodation: AccommodationType) => void;
};

const BookedAccommodationCard: FC<AccommodationCardType> = ({ accommodation, accommodationBooking, onHandleClick }) => {
  const { t } = useTranslation();
  const { bemClassName } = useBem('booked-accommodation-card');
  const { roomTypeBookings } = accommodationBooking;
  const { name, resourceLinks } = accommodation;

  return (
    <CardBase
      image={resourceLinks[0]?.url}
      title={name}
      type={CardType.BookedAccommodation}
      onClick={() => onHandleClick(accommodation)}
    >
      <div className={bemClassName()}>
        <div className={bemClassName('room-list')}>
          {roomTypeBookings.sort(roomBookingTypeSorter).map(({ luxuryLevel, roomCount }) => (
            <span
              key={`${name}-${luxuryLevel}`}
              className={bemClassName('room-item')}
              data-luxury={luxuryLevel}
            >
              {t('accommodation-card.booking', { count: roomCount, luxuryLevel: t(`luxury-level.${luxuryLevel}`) })}
            </span>
          ))}
        </div>
        <span className={bemClassName('checkmark')}>
          <Icon
            iconName="checkmark"
            color="var(--font-color-secondary)"
            width={14}
            height={10}
            iconDraw="stroke"
          />
        </span>
      </div>
    </CardBase>
  );
};

export default BookedAccommodationCard;
