import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';

import Page from '../../../shared/components/layout/page/page';
import ErrorContainer from './component/error-container';

import './error.scss';

const NotFound = () => {
  const { t } = useTranslation('common', { keyPrefix: '404' });
  const navigate = useNavigate();
  const { bemClassName } = useBem('error-page');

  return (
    <Page className={bemClassName()}>
      <ErrorContainer
        className={bemClassName('error-container')}
        title={t('title')}
        description={t('description')}
        buttonText={t('button-text')}
        buttonAction={() => navigate('/')}
      />
    </Page>
  );
};

export default NotFound;
