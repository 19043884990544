import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';

import FormStep from '../../../../shared/components/form/react-hook-form-components/form-step/form-step';
import { FormStepType } from '../../../../shared/components/form/types';
import useBookingFormContext from '../../data/context/use-booking-form-context';
import BookedAccommodationCard from '../../../accommodations/components/accommodation-card/booked-accommodation-card';
import useAccommodationContext from '../../../accommodations/data/context/use-accommodation-context';
import MoreRoomsCard from '../../../accommodations/components/accommodation-card/more-rooms-card';

import './require-more-rooms-step.scss';

const RequireMoreRoomsStep: FC<FormStepType> = ({ stepNumber = 1, currentStep = 1 }) => {
  const { bemClassName } = useBem('require-more-rooms-step');
  const { t } = useTranslation('common', { keyPrefix: 'booking-form.require-more-rooms' });
  const { primaryAccommodations, overflowAccommodations } = useAccommodationContext();
  const { setAccommodation } = useAccommodationContext();
  const { moreRooms, setMoreRooms, setMoreRoomsId, booking } = useBookingFormContext();
  const { accommodationBookings } = booking!;

  const getAccommodationById = (id: string) => {
    let accommodation;
    accommodation = primaryAccommodations.find(({ id: accommodationId }) => accommodationId === id);

    if (!accommodation) {
      accommodation = overflowAccommodations.find(({ id: accommodationId }) => accommodationId === id);
    }

    return accommodation || undefined;
  };

  useEffect(() => {}, [moreRooms]);

  const renderAccommodationList = () => {
    const accommodationList = accommodationBookings.map((accommodationBooking) => {
      const accommodation = getAccommodationById(accommodationBooking.accommodationId);
      const { roomTypeBookings } = accommodationBooking;

      if (!accommodation) return null;

      return (
        <BookedAccommodationCard
          key={accommodation.id}
          accommodation={accommodation}
          accommodationBooking={accommodationBooking}
          onHandleClick={
            roomTypeBookings.length > 1
              ? () => setAccommodation(accommodation)
              : () => {
                  setMoreRoomsId(accommodation.id);
                  setMoreRooms(true);
                }
          }
        />
      );
    });

    accommodationList.push(<MoreRoomsCard key="more-rooms" />);

    return accommodationList;
  };

  return (
    <FormStep
      key={stepNumber}
      title={t(`title`)}
      description={t(`description`)}
      stepNumber={stepNumber}
      currentStep={currentStep}
    >
      <div
        key="accommodation-list"
        className={bemClassName('selected-accommodations')}
      >
        {renderAccommodationList()}
      </div>
    </FormStep>
  );
};

export default RequireMoreRoomsStep;
