import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { BookingType } from '../../types';
import { get } from '../services/booking-service';

const useSurvey = (): UseQueryResult<BookingType, Error> =>
  useQuery({
    queryKey: ['booking'],
    queryFn: () => get(),
  });

export default useSurvey;
