import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';

import BookingContextProvider from './features/bookings/data/context/booking-form-context-provider';
import AccommodationContextProvider from './features/accommodations/data/context/accommodation-context-provider';

import '@indicia/component-library/style.css';
import 'shared/styling/global.scss';
import 'shared/styling/variables.scss';
import 'shared/styling/fonts.scss';
import 'shared/styling/typography.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>{error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <BookingContextProvider>
        <AccommodationContextProvider>
          <Outlet />
        </AccommodationContextProvider>
      </BookingContextProvider>
    </QueryClientProvider>
  );
};

export default App;
