import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useBem from '@indicia/use-bem';
import FormStep from '../../../../shared/components/form/react-hook-form-components/form-step/form-step';
import { FormStepType } from '../../../../shared/components/form/types';
import FormRadioList from '../../../../shared/components/form/react-hook-form-components/form-fields/form-radio-list/form-radio-list';
import PaymentOptionEnum from './PaymentOptionEnum';
import useBookingFormContext from '../../data/context/use-booking-form-context';

const PaymentStep: FC<FormStepType> = ({ stepNumber = 1, currentStep = 1 }) => {
  const { t } = useTranslation('common', { keyPrefix: 'booking-form.payment' });
  const { paymentOption, setPaymentOption } = useBookingFormContext();
  const { bemClassName } = useBem('form-step');

  return (
    <FormStep
      title={t(`title`)}
      description={t(`description`)}
      stepNumber={stepNumber}
      currentStep={currentStep}
    >
      <FormRadioList
        name="meetingRoom"
        onChange={(value) => setPaymentOption(value as PaymentOptionEnum)}
        selectedValue={paymentOption}
      >
        <FormRadioList.Item
          label={t('ideal')}
          value={PaymentOptionEnum.IDEAL}
        />
        <FormRadioList.Item
          label={t('credit-card')}
          value={PaymentOptionEnum.CREDIT_CARD}
        />
        <FormRadioList.Item
          label={t('bank-transfer')}
          value={PaymentOptionEnum.BANK_TRANSFER}
        />
      </FormRadioList>

      <p className={bemClassName('description')}>
        {t('info')}
      </p>
    </FormStep>
  );
};

export default PaymentStep;
