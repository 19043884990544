import { DaySelectionType } from '../../../types';
import { ReducerActionType } from '../../../../../shared/types/types';
import DaySelectionAction from './day-selection-actions';

const contactReducer = (state: DaySelectionType, action: ReducerActionType<DaySelectionAction>): DaySelectionType => {
  const { type, payload } = action;
  switch (type) {
    case DaySelectionAction.SetLongerStay:
      return {
        ...state,
        longerStay: payload as boolean,
      };
    case DaySelectionAction.SetLongerStayDescription:
      return {
        ...state,
        longerStayDescription: payload as string,
      };
    case DaySelectionAction.SetSelectedDaysError:
      return {
        ...state,
        selectedDaysError: payload as string,
      };
    default:
      throw Error('Action type not found');
  }
};

export default contactReducer;
