import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@indicia/component-library';
import useBem from '@indicia/use-bem';

import './help.scss';

const Help = () => {
  const { t } = useTranslation('common', { keyPrefix: 'help' });
  const { t: tContact } = useTranslation('common', { keyPrefix: 'contact-information' });
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const { bemClassName, bemClassNames } = useBem('help');

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('button')}>
        <IconButton
          iconName="question-mark"
          onClick={() => setShowHelp(!showHelp)}
        >
          {}
        </IconButton>
      </div>
      <div className={bemClassNames([bemClassName('content'), bemClassName('content', 'visible', showHelp)])}>
        <div className={bemClassName('content-title')}>{t('title')}</div>
        <div className={bemClassName('content-description')}>{t('description')}</div>
        <div className={bemClassName('content-name')}>{tContact('name')}</div>
        <div className={bemClassName('content-items')}>
          <span>{t('email')}</span> <span><a href={`mailto:${tContact('email')}`}>{tContact('email')}</a></span>
          <span>{t('phone')}</span> <span>{tContact('phone-number')}</span>
        </div>
      </div>
    </div>
  );
};

export default Help;
