import { Children, cloneElement, ReactElement, FC } from 'react';
import { TailSpin } from 'react-loader-spinner';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';

import ProgressBar from './react-hook-form-components/progress-bar/progress-bar';
import { Button, Container } from '../index';
import { FormStepType, FormType } from './types';
import useBookingFormContext from '../../../features/bookings/data/context/use-booking-form-context';

import './form.scss';

const Form: FC<FormType> = ({ children = undefined, id }) => {
  const { bemClassName, bemClassNames } = useBem('form');
  const { t } = useTranslation();
  const totalSteps = Children.count(children);
  const { allowNextStep, formStep, handleFormStep, handleNextStep, loading } = useBookingFormContext();

  const childrenWithProps = Children.map(children, (child, stepNumber) =>
    cloneElement(child as ReactElement<FormStepType>, { stepNumber, currentStep: formStep }),
  );

  return (
    <div
      id={id}
      className={bemClassName()}
    >
      <div className={bemClassName('steps')}>{childrenWithProps}</div>
      <div className={bemClassName('actions')}>
        <Container>
          <div className={bemClassName('button-container')}>
            {formStep > 1 && <Button onClick={() => handleFormStep(formStep - 1)}>{t('form.previous')}</Button>}
          </div>
          <ProgressBar
            current={formStep}
            total={totalSteps}
            onStepChange={handleFormStep}
          />
          <div
            className={bemClassNames([
              bemClassName('button-container'),
              bemClassName('button-container', 'continue'),
              bemClassName('button-container', 'loading', loading),
            ])}
          >
            <Button
              disabled={!allowNextStep(formStep)}
              type={formStep < totalSteps ? 'button' : 'submit'}
              onClick={handleNextStep}
            >
              {t('form.next')}
              <span className="loading">
                <TailSpin
                  width={30}
                  height={30}
                  color="#000"
                />
              </span>
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Form;
