import { FC } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import useBem from '@indicia/use-bem';
import { LoaderType, LoaderTypeEnum } from './types';

import './loader.scss';

const Loader: FC<LoaderType> = ({ type = LoaderTypeEnum.Default }) => {
  const { bemClassName, bemClassNames } = useBem('loader');

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(type)])}>
      <InfinitySpin />
    </div>
  );
};

export default Loader;
