import { createContext } from 'react';

import { AccommodationContextType, AccommodationType } from '../../types/types';

const AccommodationContext = createContext<AccommodationContextType>({
  accommodation: undefined,
  setAccommodation: (accommodation?: AccommodationType) => {
    throw new Error(`Not implemented yet, accommodation: ${accommodation}`);
  },
  delegationRoomCount: 0,
  setDelegationRoomCount: (count: number) => {
    throw new Error(`Not implemented yet, count: ${count}`);
  },
  primaryAccommodations: [],
  setPrimaryAccommodations: (accommodations: AccommodationType[]) => {
    throw new Error(`Not implemented yet, accommodations: ${accommodations}`);
  },
  overflowAccommodations: [],
  setOverflowAccommodations: (accommodations: AccommodationType[]) => {
    throw new Error(`Not implemented yet, accommodations: ${accommodations}`);
  },
  getAccommodationNameById: (id: string) => {
    throw new Error(`Not implemented yet, id: ${id}`);
  },
});

export default AccommodationContext;
