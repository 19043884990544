import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';

import ConfirmationSection from './confirmation-section';
import { AccommodationBookingType, RoomBookingType } from '../../types';
import useBookingFormContext from '../../data/context/use-booking-form-context';
import useAccommodationContext from '../../../accommodations/data/context/use-accommodation-context';

import './confirmation-overview.scss';

const ConfirmationOverview: FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'booking-form.confirmation.overview' });
  const { t: tLuxury } = useTranslation('common', { keyPrefix: 'luxury-level' });
  const { t: tGeneric } = useTranslation('common', { keyPrefix: 'generic' });
  const { bemClassName } = useBem('confirmation-overview');
  const { booking } = useBookingFormContext();
  const { accommodationBookings, amountOfPeople, longerStay, reserveConferenceRoom } = booking!;
  const { getAccommodationNameById } = useAccommodationContext();

  const renderAccommodationBookings: FC<AccommodationBookingType> = ({ accommodationId, roomTypeBookings }) => {
    const records = roomTypeBookings.map(({ luxuryLevel, roomCount }: RoomBookingType) => ({
      text: tLuxury(luxuryLevel),
      value: roomCount,
    }));

    const name = getAccommodationNameById(accommodationId);

    return (
      <ConfirmationSection
        key={name}
        title={name}
        records={records}
      />
    );
  };

  const calculateTotalPrice = accommodationBookings.reduce((acc, { roomTypeBookings }) => {
    const totalForAccommodation = roomTypeBookings.reduce(
      (accInner, { roomCount, pricePerNight }) => accInner + roomCount * pricePerNight,
      0,
    );
    return acc + totalForAccommodation;
  }, 0);

  const selectedRooms = accommodationBookings.reduce((acc, { roomTypeBookings }) => {
    const totalForAccommodation = roomTypeBookings.reduce((accInner, { roomCount }) => accInner + roomCount, 0);
    return acc + totalForAccommodation;
  }, 0);

  const showRoomAlert = parseInt(`${amountOfPeople}`, 10) !== selectedRooms;

  return (
    <>
      {showRoomAlert && (
        <div className={bemClassName('room-alert')}>
          {t('room-alert', { selected: selectedRooms, requested: amountOfPeople })}
        </div>
      )}
      <div className={bemClassName()}>
        {booking && (
          <>
            <ConfirmationSection records={[{ text: t('number-of-guests'), value: booking.amountOfPeople }]} />
            {booking.accommodationBookings.map(renderAccommodationBookings)}
            <ConfirmationSection
              records={[{ text: t('conference-room'), value: tGeneric(reserveConferenceRoom ? 'yes' : 'no') }]}
            />
            <ConfirmationSection records={[{ text: t('longer-stay'), value: tGeneric(longerStay ? 'yes' : 'no') }]} />
            <ConfirmationSection records={[{ text: t('total'), value: calculateTotalPrice, renderMode: 'currency' }]} />
          </>
        )}
      </div>
    </>
  );
};

export default ConfirmationOverview;
