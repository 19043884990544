import { FC } from 'react';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';

import useAccommodationContext from '../../../data/context/use-accommodation-context';

import './accommodation-detail-description.scss';

const AccommodationDetailDescription: FC = () => {
  const { accommodation } = useAccommodationContext();
  const { description } = accommodation ?? { description: '' };
  const { bemClassName } = useBem('accommodation-detail-description');
  const { t } = useTranslation();

  return (
    <div className={bemClassName()}>
      <span className={bemClassName('subtitle')}>{t('accommodation-details.about')}</span>
      <div className={bemClassName('content')}>
        <h3>{description}</h3>
      </div>
    </div>
  );
};

export default AccommodationDetailDescription;
