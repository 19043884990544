import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';

import FormStep from '../../../../shared/components/form/react-hook-form-components/form-step/form-step';
import FormInputField from '../../../../shared/components/form/react-hook-form-components/form-fields/form-input-field';
import FormTextArea from '../../../../shared/components/form/react-hook-form-components/form-fields/form-text-area';
import FormToggle from '../../../../shared/components/form/react-hook-form-components/form-fields/form-toggle';
import { FormInputEnum, FormStepType } from '../../../../shared/components/form/types';
import useBookingFormContext from '../../data/context/use-booking-form-context';
import BookingAction from '../../data/reducers/booking-reducer/booking-actions';
import DaySelectionAction from '../../data/reducers/day-selection-reducer/day-selection-actions';

import './guest-information-step.scss';

const GuestInformationStep: FC<FormStepType> = ({ stepNumber = 1, currentStep = 1 }) => {
  const { bemClassName } = useBem('guest-information-step');
  const { t } = useTranslation('common', { keyPrefix: 'booking-form.guest-information' });
  const {
    booking,
    daySelection: { longerStay, longerStayDescription },
    daySelectionDispatcher,
    bookingDispatcher,
  } = useBookingFormContext();
  const { amountOfPeople } = booking!;

  return (
    <FormStep
      title={t(`title`)}
      description={t(`description`)}
      stepNumber={stepNumber}
      currentStep={currentStep}
    >
      <div className={bemClassName()}>
        <FormInputField
          name="amountOfGuests"
          formInputType={FormInputEnum.Number}
          label={t('amount-of-guests')}
          isFilled
          onChange={(value) => bookingDispatcher({ type: BookingAction.SetAmountOfPeople, payload: value })}
          value={`${amountOfPeople}`}
        />

        <div className={bemClassName('day-selection')}>
          <div className={bemClassName('days-container')}>
            <span className={bemClassName('days-container-title')}>{t('number-of-nights')}</span>
            <div className={bemClassName('days')}>
              <div className={bemClassName('attend')}>{t('attend-nato')}</div>
              <FormToggle
                name={t('longer-stay')}
                label={t('longer-stay')}
                value={longerStay}
                onChange={(value) => {
                  daySelectionDispatcher({ type: DaySelectionAction.SetLongerStay, payload: value });
                }}
              />
            </div>
          </div>
          {longerStay && (
            <div className={bemClassName('longer-stay')}>
              <FormTextArea
                name={t('longer-stay-description')}
                label={t('longer-stay-description')}
                isFilled
                onChange={(value) => {
                  daySelectionDispatcher({ type: DaySelectionAction.SetLongerStayDescription, payload: value });
                }}
                placeholder={t('longer-stay-placeholder')}
                value={longerStayDescription}
              />
            </div>
          )}
        </div>
      </div>
    </FormStep>
  );
};

export default GuestInformationStep;
