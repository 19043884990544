import { emailRegex, phoneRegex } from './regexes';
import { AccommodationBookingType, ContactInformationType } from '../../types';

const validateContactInformation = (contactInformation: ContactInformationType): boolean => {
  const { name, emailAddress, phoneNumber } = contactInformation;
  return emailRegex.test(emailAddress) && emailAddress.length <= 254 && phoneRegex.test(phoneNumber) && name !== '';
};

const validateGuestInformation = (amountOfGuests: number): boolean =>
  amountOfGuests !== undefined && !Number.isNaN(amountOfGuests!) && amountOfGuests > 0;

const validateReserveConferenceRoom = (reserveConferenceRoom?: boolean): boolean => reserveConferenceRoom !== undefined;

const validatePrimaryAccommodationBooking = (accommodationBookings: AccommodationBookingType[]): boolean =>
  accommodationBookings.length > 0;

export {
  validateContactInformation,
  validateGuestInformation,
  validateReserveConferenceRoom,
  validatePrimaryAccommodationBooking,
};
