import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import FormStep from '../../../../shared/components/form/react-hook-form-components/form-step/form-step';
import { FormStepType } from '../../../../shared/components/form/types';
import FormRadioList from '../../../../shared/components/form/react-hook-form-components/form-fields/form-radio-list/form-radio-list';
import useBookingFormContext from '../../data/context/use-booking-form-context';
import BookingAction from '../../data/reducers/booking-reducer/booking-actions';

const RequireMeetingRoomStep: FC<FormStepType> = ({ stepNumber = 1, currentStep = 1 }) => {
  const { t } = useTranslation('common', { keyPrefix: 'booking-form.require-meeting-room' });
  const { t: tGeneric } = useTranslation('common', { keyPrefix: 'generic' });
  const { booking, bookingDispatcher } = useBookingFormContext();
  const { reserveConferenceRoom } = booking!;
  let selectedValue;
  if (reserveConferenceRoom !== undefined) {
    selectedValue = reserveConferenceRoom ? 'true' : 'false';
  }

  return (
    <FormStep
      title={t(`title`)}
      description={t(`description`)}
      stepNumber={stepNumber}
      currentStep={currentStep}
    >
      <FormRadioList
        name="meetingRoom"
        onChange={(value) =>
          bookingDispatcher({ type: BookingAction.SetReserveConferenceRoom, payload: value === 'true' })
        }
        selectedValue={selectedValue}
      >
        <FormRadioList.Item
          label={tGeneric('yes')}
          value="true"
        />
        <FormRadioList.Item
          label={tGeneric('no')}
          value="false"
        />
      </FormRadioList>
    </FormStep>
  );
};

export default RequireMeetingRoomStep;
