enum BookingAction {
  SetAmountOfPeople = 'SetAmountOfPeople',
  SetFrom = 'SetFrom',
  SetTo = 'SetTo',
  SetReserveConferenceRoom = 'SetReserveConferenceRoom',
  SetLongerStay = 'SetLongerStay',
  SetLongerStayDescription = 'SetLongerStayDescription',
  SetBooking = 'SetBooking',
  SetContactWithHotel = 'SetContactWithHotel',
  SetAccommodationBookings = 'SetAccommodationBookings',
  RemoveAccommodationBookings = 'RemoveAccommodationBookings',
}

export default BookingAction;
