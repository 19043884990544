import { ReactNode } from 'react';
import useBem from '@indicia/use-bem';
import AccommodationCard from '../accommodation-card/accommodation-card';

import './accommodation-card-list.scss';

type AccommodationCardListType = {
  children: ReactNode;
};

const AccommodationCardList = ({ children }: AccommodationCardListType) => {
  const { bemClassName } = useBem('accommodation-card-list');

  return <div className={bemClassName()}>{children}</div>;
};

AccommodationCardList.Item = AccommodationCard;

export default AccommodationCardList;
