import { FC } from 'react';
import useBem from '@indicia/use-bem';

import { ConfirmationOverviewSectionType } from './types';
import ConfirmationRecord from './confirmation-record';

const ConfirmationSection: FC<ConfirmationOverviewSectionType> = ({ title, records }) => {
  const { bemClassName } = useBem('confirmation-overview');

  return (
    <div className={bemClassName('section')}>
      {title && <div className={bemClassName('header')}>{title}</div>}
      {records.map(({ text, value, renderMode }) => (
        <ConfirmationRecord
          key={text}
          text={text}
          value={value}
          renderMode={renderMode}
        />
      ))}
    </div>
  );
};

export default ConfirmationSection;
