import { FC } from 'react';
import useBem from '@indicia/use-bem';
import { Icon } from '@indicia/component-library';

import { IconButtonType } from './types';

import './icon-button.scss';

const IconButton: FC<IconButtonType> = ({
  label,
  onClick,
  iconName,
  iconHeight = 17,
  iconWidth = 17,
  disabled = false,
}) => {
  const { bemClassName } = useBem('icon-button');

  return (
    <button
      className={bemClassName()}
      type="button"
      onClick={onClick}
      title={label}
      aria-label={label}
      disabled={disabled}
    >
      <Icon
        iconName={iconName}
        width={iconWidth}
        height={iconHeight}
      />
    </button>
  );
};

export default IconButton;
