import { FC, ComponentType, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import http from '../../shared/utils/http-client';
import Loader from '../../shared/components/loader/loader';

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component }) => {
  const loader = () => <Loader />;
  const { getAccessTokenSilently } = useAuth0();

  // taken from: https://stackoverflow.com/a/68407340
  useEffect(() => {
    http.tokenGenerator = getAccessTokenSilently;
  }, [getAccessTokenSilently]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: loader,
  });

  return <Component />;
};

export default ProtectedRoute;
