import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';
import { AnimatePresence, motion } from 'framer-motion';

import AccommodationDetailHeader from './components/accommodation-detail-header';
import Page from '../../../../shared/components/layout/page/page';
import AccommodationDetailGallery from './components/accommodation-detail-gallery';
import AccommodationDetailDescription from './components/accommodation-detail-description';
import AccommodationDetailRoomList from './room-list-components/accommodation-detail-room-list';
import { RoomType } from '../../types/types';
import { RoomBookingType } from '../../../bookings/types';
import useAccommodationContext from '../../data/context/use-accommodation-context';
import useBookingFormContext from '../../../bookings/data/context/use-booking-form-context';
import LuxuryLevel from '../../data/utils/luxury-level';
import BookingAction from '../../../bookings/data/reducers/booking-reducer/booking-actions';
import { Button } from '../../../../shared/components';
import { roomTypeSorter } from '../../../bookings/data/utils/room-type-sorter';

import './accommodation-details.scss';
import { BookingProcessSteps } from '../../../bookings/data/utils/enums';

const DELEGATION_ROOM_DEFAULT = 18;

const AccommodationDetails: FC = () => {
  const { bemClassName } = useBem('accommodation-details');
  const { t } = useTranslation('common', { keyPrefix: 'accommodation-details' });
  const { t: tGeneric } = useTranslation('common', { keyPrefix: 'generic' });
  const { t: tLuxury } = useTranslation('common', { keyPrefix: 'luxury-level' });

  const { accommodation, setAccommodation, delegationRoomCount, setDelegationRoomCount } = useAccommodationContext();
  const { booking, bookingDispatcher, handleNextStep, formStep } = useBookingFormContext();
  const { accommodationBookings } = booking!;

  const [selectedRoomCount, setSelectedRoomCount] = useState(delegationRoomCount);

  const transformRoomTypeBooking = (roomType: RoomType, roomCount: number): RoomBookingType => {
    const { id: roomId, pricePerNight, luxuryLevel } = roomType;

    return {
      roomId,
      pricePerNight,
      luxuryLevel,
      roomCount,
    };
  };

  useEffect(() => {
    if (accommodation?.id !== accommodationBookings[0]?.accommodationId) {
      setSelectedRoomCount(DELEGATION_ROOM_DEFAULT);
    } else {
      const count = accommodationBookings
        .find(({ accommodationId }) => accommodationId === accommodation?.id)
        ?.roomTypeBookings.find(({ luxuryLevel }) => luxuryLevel === LuxuryLevel.DelegationRoom)?.roomCount;
      setSelectedRoomCount(count || DELEGATION_ROOM_DEFAULT);
    }
  }, [accommodation]);

  const closeAccommodationDetails = () => {
    setAccommodation(undefined);
  };

  const confirmAccommodationDetails = () => {
    const { roomTypes } = accommodation!;
    const headOfDelegationRoom = roomTypes.find(({ luxuryLevel }) => luxuryLevel === LuxuryLevel.HeadOfDelegation);
    const ministersRoom = roomTypes.find(({ luxuryLevel }) => luxuryLevel === LuxuryLevel.MinistersSuite);
    const delegationRoom = roomTypes.find(({ luxuryLevel }) => luxuryLevel === LuxuryLevel.DelegationRoom);

    bookingDispatcher({
      type: BookingAction.SetAccommodationBookings,
      payload: [
        {
          accommodationId: accommodation!.id,
          roomTypeBookings: [
            transformRoomTypeBooking(headOfDelegationRoom!, 1),
            transformRoomTypeBooking(ministersRoom!, 2),
            transformRoomTypeBooking(delegationRoom!, selectedRoomCount),
          ],
        },
      ],
    });

    closeAccommodationDetails();

    if (formStep === BookingProcessSteps.AccommodationsSelection) {
      handleNextStep();
    }
  };

  return (
    <AnimatePresence>
      {accommodation && (
        <motion.div
          initial={{ top: '100vh' }}
          animate={{ top: 0 }}
          exit={{ top: '100vh' }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
          className={bemClassName()}
        >
          <Page>
            <AccommodationDetailHeader />
            <AccommodationDetailGallery />
            <AccommodationDetailDescription />
            <AccommodationDetailRoomList>
              {accommodation.roomTypes.sort(roomTypeSorter).map((roomType) => {
                const { luxuryLevel, available, amenities, description, pricePerNight, resourceLinks } = roomType;

                return (
                  <AccommodationDetailRoomList.Item
                    key={roomType.id}
                    luxuryLevel={luxuryLevel}
                    maxCount={available}
                    title={tLuxury(luxuryLevel)}
                    description={description}
                    amenities={amenities}
                    count={selectedRoomCount}
                    resourceLinks={resourceLinks}
                    price={pricePerNight}
                    displayPrice={luxuryLevel === LuxuryLevel.DelegationRoom}
                    onChange={(count: number) => {
                      setSelectedRoomCount(count);
                      setDelegationRoomCount(count);
                    }}
                  />
                );
              })}
            </AccommodationDetailRoomList>
            <div className={bemClassName('footer')}>
              <Button
                type="button"
                onClick={closeAccommodationDetails}
              >
                {tGeneric('previous')}
              </Button>
              <Button
                type="button"
                onClick={confirmAccommodationDetails}
              >
                {t('confirm')}
              </Button>
            </div>
          </Page>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AccommodationDetails;
