import { ReactNode } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export type FormType = {
  children: ReactNode;
  id: string;
  submitDisabled?: boolean;
};

export type SelectOptionType<T> = {
  value: T;
  label: string;
  description?: string;
  isDisabled?: boolean;
};

export type FormStepType = {
  children?: ReactNode;
  title?: string;
  description?: string;
  isVisible?: boolean;
  stepNumber?: number;
  currentStep?: number;
};

export type FormRowType = {
  children: ReactNode;
  title?: string;
  onDelete?: () => void;
  error:
    | Merge<FieldError, FieldErrorsImpl<{ name: string; email: string; phoneNumber: string; primary: boolean }>>
    | undefined;
};

export type FormInputFieldType = {
  value?: string;
  name: string;
  formInputType: FormInputEnum;
  required?: boolean;
  label: string;
  placeholder?: string;
  isVisible?: boolean;
  isFilled: boolean;
  error?: string;
  disabled?: boolean;
  size?: InputSizeEnum;
  onChange: (value: string) => void;
  onBlur?: () => void;
};

export type FormToggleType = {
  value: boolean;
  name: string;
  label: string;
  onChange: (value: boolean) => void;
  error?: string;
  disabled?: boolean;
};

export type FormTextAreaType = {
  name: string;
  label: string;
  required?: boolean;
  amountOfRows?: number;
  isFilled: boolean;
  isVisible?: boolean;
  error?: string;
  disabled?: boolean;
  placeholder: string;
  value?: string;
  onChange: (value: string) => void;
};

export type SelectType<T> = {
  name: string;
  required?: boolean;
  label: string;
  register: any;
  isFilled: boolean;
  control: any;
  isMulti?: boolean;
  isVisible?: boolean;
  currentValue?: SelectOptionType<T> | SelectOptionType<T>[];
  onSearchInputChange?: (value?: string) => void;
  error: Merge<FieldError, (FieldError | undefined)[]> | undefined;
  disabled?: boolean;
};

export type SelectInputType<T> = SelectType<T> & {
  options: SelectOptionType<T>[];
  onSelectedValueChange?: (value: SelectOptionType<T> | SelectOptionType<T>[]) => void;
  isClearable?: boolean;
  size?: InputSizeEnum;
};

export type CreatableSelectInputType<T> = SelectType<T> & {
  options?: SelectOptionType<T>[];
  showMenu?: boolean;
};

export type RadioListType = {
  children: ReactNode;
  name: string;
  onChange: (value: string) => void;
  selectedValue?: string;
  error?:
    | Merge<FieldError, FieldErrorsImpl<{ name: string; email: string; phoneNumber: string; primary: boolean }>>
    | undefined;
};

export type RadioItemType = {
  label: string;
  value: string;
  name?: string;
  onChange?: (value: string) => void;
  selectedValue?: string;
};

export enum FormInputEnum {
  Text = 'text',
  Number = 'number',
  Radio = 'radio',
  Date = 'date',
  DateTime = 'datetime-local',
  Month = 'month',
  Url = 'text',
}

export enum InputSizeEnum {
  Default = 'default',
  SpanTwo = 'span-2',
  SpanThree = 'span-3',
}
