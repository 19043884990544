enum ContactInformationAction {
  SetAll = 'SetAll',
  SetName = 'SetName',
  SetNameError = 'SetNameError',
  SetEmail = 'SetEmail',
  SetEmailError = 'SetEmailError',
  SetPhone = 'SetPhone',
  SetPhoneError = 'SetPhoneError',
}

export default ContactInformationAction;
