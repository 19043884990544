import { FC, useEffect, useMemo, useState } from 'react';

import { ContextType } from '../../../../shared/types/types';
import { AccommodationType } from '../../types/types';
import AccommodationContext from './accommodation-context';
import getAccommodationById from '../utils/get-accommodation-by-id';

const AccommodationContextProvider: FC<ContextType> = ({ children = undefined }) => {
  const packageDelegationRooms = 18;
  const [accommodation, setAccommodation] = useState<AccommodationType | undefined>(undefined);
  const [primaryAccommodations, setPrimaryAccommodations] = useState<AccommodationType[]>([]);
  const [overflowAccommodations, setOverflowAccommodations] = useState<AccommodationType[]>([]);
  const [delegationRoomCount, setDelegationRoomCount] = useState<number>(packageDelegationRooms);

  const getAccommodationNameById = (id: string) => {
    const acc = getAccommodationById(id, primaryAccommodations, overflowAccommodations);

    return acc?.name || id;
  };

  useEffect(() => {
    if (accommodation) {
      setDelegationRoomCount(packageDelegationRooms);
    }
  }, [accommodation]);

  const context = useMemo(
    () => ({
      accommodation,
      setAccommodation,
      delegationRoomCount,
      setDelegationRoomCount,
      primaryAccommodations,
      setPrimaryAccommodations,
      overflowAccommodations,
      setOverflowAccommodations,
      getAccommodationNameById,
    }),
    [accommodation, primaryAccommodations, overflowAccommodations, delegationRoomCount],
  );

  return <AccommodationContext.Provider value={context}>{children}</AccommodationContext.Provider>;
};

export default AccommodationContextProvider;
